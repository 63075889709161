import React, { useRef } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FiCompass, FiHome, FiMoon, FiSettings, FiStar, FiSun, FiTrendingUp } from 'react-icons/fi';
import { HiOutlineMenu, HiX } from 'react-icons/hi';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from '@chakra-ui/next-js';
import NextLink from 'next/link';
import { AddIcon } from '@chakra-ui/icons';
import { useAuthState } from '../../../contexts/AuthContext';
import { OrgSelector } from './orgSelector';
import { NavItem } from '../NavItem';
import { NavList } from './NavList';
import { Logo } from '../../Logo';
import { useOrgState } from '../../../contexts/OrgContext';

interface LinkItemProps {
  name: string;
  icon: any;
  linkAddress: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: FiHome, linkAddress: '' },
  { name: 'Customers', icon: FiTrendingUp, linkAddress: 'customers' },
  { name: 'Plans', icon: FiCompass, linkAddress: 'plans' },
  { name: 'Subscriptions', icon: FiStar, linkAddress: 'subscriptions' }
];
const DesktopLargeNavContent = (props: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const { isSettingCurrentOrganisation, isAuthenticated, user } = useAuthState();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box as='nav' {...props}>
      <Flex justify='space-between'>
        <Spacer />
        <HStack spacing={6} mr={4}>
          {user && <OrgSelector />}
        </HStack>
        <HStack divider={<StackDivider height='6' alignSelf='unset' borderColor='bg-subtle' />}>
          <IconButton
            size='lg'
            fontSize='2xl'
            color={useColorModeValue('gray.500', 'gray.100')}
            variant='ghost'
            icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
            onClick={toggleColorMode}
            aria-label='Toggle color mode'
          />
          <IconButton
            size='lg'
            fontSize='2xl'
            color={useColorModeValue('gray.500', 'gray.100')}
            variant='ghost'
            icon={isOpen ? <HiX /> : <HiOutlineMenu />}
            onClick={onOpen}
            aria-label='Toggle color mode'
          />
        </HStack>

        <Drawer placement='left' initialFocusRef={menuButtonRef} isOpen={isOpen} onClose={onClose} size='full'>
          <DrawerOverlay />
          <DrawerContent bg='bg-step'>
            <DrawerHeader padding={4}>
              <Flex height='80px' align='center' justify='space-between'>
                <Link href='/'>
                  <Logo />
                </Link>
                <HStack spacing={0}>
                  <IconButton
                    size='lg'
                    fontSize='2xl'
                    color={useColorModeValue('gray.500', 'gray.100')}
                    variant='ghost'
                    icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
                    onClick={toggleColorMode}
                    aria-label='Toggle color mode'
                  />
                  <IconButton
                    size='lg'
                    fontSize='2xl'
                    color={useColorModeValue('gray.500', 'gray.100')}
                    variant='ghost'
                    icon={isOpen ? <HiX /> : <HiOutlineMenu />}
                    onClick={onClose}
                    aria-label='Toggle color mode'
                  />
                </HStack>
              </Flex>
            </DrawerHeader>
            <DrawerBody>
              {!isSettingCurrentOrganisation && isAuthenticated && (
                <>
                  <OrgSelector onSelect={onClose} mobile />

                  {LinkItems.map((link) => (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <NavItem key={link.name} linkAddress={link.linkAddress} mx={0}>
                      {link.name}
                    </NavItem>
                  ))}

                  <Divider mx='auto' borderColor='subtle-border' w='96%' my={4} />

                  <NavItem linkAddress='/billing' mx={0}>
                    Billing
                  </NavItem>
                  <NavItem linkAddress='/settings' mx={0}>
                    Settings
                  </NavItem>

                  <Button as='a' size='lg' fontWeight={400} href='/sign-out' colorScheme='brand' w='100%' my={6}>
                    Sign Out
                  </Button>
                </>
              )}
              {!user && (
                <HStack mt='6'>
                  <Link href='/sign-in/'>
                    <Button as='a' flex='1' colorScheme='brand' variant='outline' color='accent' fontWeight='semibold'>
                      Sign in
                    </Button>
                  </Link>
                  <Link flex='1' href='/sign-up/'>
                    <Button w='100%' colorScheme='brand' fontWeight='semibold'>
                      Request access
                    </Button>
                  </Link>
                </HStack>
              )}
              {/* Add OrganisationNavbar */}
              {user && user.organisations && user?.organisations?.length === 0 && (
                <>
                  <Button variant='ghost' onClick={onClose}>
                    <Link href='/org-create'>
                      <AddIcon mr='4' />
                      Add organisation
                    </Link>
                  </Button>
                  <Button as='a' size='lg' fontWeight={400} href='/sign-out' colorScheme='brand' my={6} w='100%'>
                    Sign Out
                  </Button>
                </>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <NavList pos='absolute' insetX='0' bg='brand.500' top='64px' animate={isOpen ? 'enter' : 'exit'}>
          <Stack spacing='0' divider={<StackDivider borderColor='whiteAlpha.200' />}>
            {user && isOpen && <OrgSelector />}
            {isAuthenticated &&
              isOpen &&
              LinkItems.map((link) => (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <NavItem key={link.name} linkAddress={link.linkAddress}>
                  {link.name}
                </NavItem>
              ))}
            {!isSettingCurrentOrganisation && user && isOpen && (
              <HStack>
                <Button as='a' fontSize='sm' fontWeight={400} href='/sign-out' colorScheme='brand' w='full'>
                  Sign Out
                </Button>
              </HStack>
            )}
            {!user && isOpen && (
              <HStack>
                <Link href='/sign-in/'>
                  <Button as='a' href='/sign-in/' colorScheme='brand' w='full'>
                    Sign in
                  </Button>
                </Link>

                <Button as='a' display={{ base: 'none', md: 'inline-flex' }} href='/sign-up/' isDisabled variant='link'>
                  Sign Up
                </Button>
              </HStack>
            )}
          </Stack>
        </NavList>
      </Flex>
    </Box>
  );
};
const DesktopXlargeNavContent = (props: BoxProps) => {
  const { isAuthenticated, user } = useAuthState();
  const { currentOrganisation } = useOrgState();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box as='nav' {...props}>
      <Flex justify='space-between'>
        <Spacer />
        <HStack spacing={6} mr={4}>
          {user && <OrgSelector />}
          <ButtonGroup variant='ghost' spacing={2}>
            {isAuthenticated &&
              LinkItems.map((link) => (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <NavItem key={link.name} linkAddress={link.linkAddress}>
                  {link.name}
                </NavItem>
              ))}

            {/* {isAuthenticated
              ? links.authenticated.map((link) => (
                  <Link href={link.url} key={link.title}>
                    <Button {...link.props}>{link.title}</Button>
                  </Link>
                ))
              : links.anonymous.map((link) => (
                  <Link href={link.url} key={link.title}>
                    <Button {...link.props}>{link.title}</Button>
                  </Link>
                ))} */}
            {user && (
              <HStack>
                <IconButton
                  icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
                  onClick={toggleColorMode}
                  aria-label='Toggle color mode'
                />
                <Menu>
                  <MenuButton as={Button}>
                    <FiSettings fontSize='1.25rem' aria-label='Settings' />
                  </MenuButton>
                  <MenuList>
                    <Link
                      href={`/dashboard/${currentOrganisation?.id || ''}/billing`}
                      color='body'
                      _hover={{ textDecoration: 'none' }}
                    >
                      <MenuItem minH='48px'>
                        <Text ml={2}>Billing</Text>
                      </MenuItem>
                    </Link>
                    <Link
                      href={`/dashboard/${currentOrganisation?.id || ''}/settings`}
                      color='body'
                      _hover={{ textDecoration: 'none' }}
                    >
                      <MenuItem minH='48px'>
                        <Text ml={2}>Settings</Text>
                      </MenuItem>
                    </Link>
                    <Link href='/sign-out' color='body' _hover={{ textDecoration: 'none' }}>
                      <MenuItem minH='48px'>
                        <Text ml={2}>Sign Out</Text>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </Menu>
              </HStack>
            )}
            {!user && (
              <HStack spacing={4} divider={<StackDivider height='6' alignSelf='unset' borderColor='bg-subtle' />}>
                <HStack spacing={6}>
                  <Button as={NextLink} href='/sign-in/' w='full' colorScheme='brand' variant='solid' bgColor='accent'>
                    Sign in
                  </Button>

                  <Button as={NextLink} href='/sign-up/' w='full' colorScheme='brand' variant='link'>
                    Sign up
                  </Button>
                </HStack>

                <IconButton
                  icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
                  onClick={toggleColorMode}
                  aria-label='Toggle color mode'
                />
              </HStack>
            )}
          </ButtonGroup>
        </HStack>
      </Flex>
    </Box>
  );
};
const MobileNavContent = (props: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, user } = useAuthState();
  const { colorMode, toggleColorMode } = useColorMode();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const logoColor1 = useColorModeValue('brand.400', 'whiteAlpha.500');

  return (
    <Box {...props}>
      <HStack divider={<StackDivider height='6' alignSelf='unset' borderColor='bg-subtle' />}>
        <IconButton
          size='lg'
          fontSize='2xl'
          color={useColorModeValue('gray.500', 'gray.100')}
          variant='ghost'
          icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
          onClick={toggleColorMode}
          aria-label='Toggle color mode'
        />
        <IconButton
          size='lg'
          fontSize='2xl'
          color={useColorModeValue('gray.500', 'gray.100')}
          variant='ghost'
          icon={isOpen ? <HiX /> : <HiOutlineMenu />}
          onClick={onOpen}
          aria-label='Toggle color mode'
        />
      </HStack>

      <Drawer placement='left' initialFocusRef={menuButtonRef} isOpen={isOpen} onClose={onClose} size='full'>
        <DrawerOverlay />
        <DrawerContent bg='bg-step'>
          <DrawerHeader padding={4}>
            <Flex height='80px' align='center' justify='space-between'>
              <Link href='/'>
                <Logo />
              </Link>
              <HStack spacing={0}>
                <IconButton
                  size='lg'
                  fontSize='2xl'
                  color={useColorModeValue('gray.500', 'gray.100')}
                  variant='ghost'
                  icon={colorMode === 'light' ? <FiMoon fontSize='1.25rem' /> : <FiSun fontSize='1.25rem' />}
                  onClick={toggleColorMode}
                  aria-label='Toggle color mode'
                />
                <IconButton
                  size='lg'
                  fontSize='2xl'
                  color={useColorModeValue('gray.500', 'gray.100')}
                  variant='ghost'
                  icon={isOpen ? <HiX /> : <HiOutlineMenu />}
                  onClick={onClose}
                  aria-label='Toggle color mode'
                />
              </HStack>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {isAuthenticated && (
              <>
                <OrgSelector onSelect={onClose} mobile />

                {LinkItems.map((link) => (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <NavItem key={link.name} linkAddress={link.linkAddress} mx={0}>
                    {link.name}
                  </NavItem>
                ))}

                <Divider mx='auto' borderColor='subtle-border' w='96%' my={4} />

                <NavItem linkAddress='/billing' mx={0}>
                  Billing
                </NavItem>
                <NavItem linkAddress='/settings' mx={0}>
                  Settings
                </NavItem>
              </>
            )}

            {/* Add OrganisationNavbar */}
            {user && user.organisations && user?.organisations?.length === 0 && (
              <Button variant='ghost' onClick={onClose}>
                <Link href='/createOrganisation'>
                  <AddIcon mr='4' />
                  Add organisation
                </Link>
              </Button>
            )}

            {isAuthenticated && (
              <Button as='a' size='lg' fontWeight={400} href='/sign-out' colorScheme='brand' w='100%' my={6}>
                Sign Out
              </Button>
            )}
            {!user && (
              <HStack mt='6'>
                <Link href='/sign-in/'>
                  <Button as='a' flex='1' colorScheme='brand' variant='outline' color='accent' fontWeight='semibold'>
                    Sign in
                  </Button>
                </Link>
                <Link flex='1' href='/sign-up/'>
                  <Button w='100%' colorScheme='brand' fontWeight='semibold'>
                    Request access
                  </Button>
                </Link>
              </HStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <NavList pos='absolute' insetX='0' bg='brand.500' top='64px' animate={isOpen ? 'enter' : 'exit'}>
        <Stack spacing='0' divider={<StackDivider borderColor='whiteAlpha.200' />}>
          {user && isOpen && <OrgSelector />}
          {isAuthenticated &&
            isOpen &&
            LinkItems.map((link) => (
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              <NavItem key={link.name} linkAddress={link.linkAddress}>
                {link.name}
              </NavItem>
            ))}

          {user && isOpen && (
            <HStack>
              <Button
                as='a'
                fontSize='sm'
                fontWeight={400}
                href='/sign-out'
                color='brand'
                bgColor={logoColor1}
                w='full'
              >
                Sign Out
              </Button>
            </HStack>
          )}
          {!user && isOpen && (
            <HStack>
              <Link href='/sign-in/'>
                <Button as='a' href='/sign-in/' variant='solid' color='brand' w='full'>
                  Sign In
                </Button>
              </Link>
              <Button as='a' display={{ base: 'none', md: 'inline-flex' }} href='/sign-up/' isDisabled variant='link'>
                Sign Up
              </Button>
            </HStack>
          )}
        </Stack>
      </NavList>
    </Box>
  );
};
export const NavContent = {
  Mobile: MobileNavContent,
  DesktopLarge: DesktopLargeNavContent,
  DesktopXlarge: DesktopXlargeNavContent
};
