import { NextSeo } from 'next-seo';
import React, { FC } from 'react';
import { Box, BoxProps, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Footer } from './Footer';
import { Navbar } from './NavBar/NavbarStandard';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  seoOptions?: any;
}

const Page: FC<PageProps> = ({ children, seoOptions, ...props }) => {
  const themeColor = useColorModeValue('#E2E8F0', '#1A202C');
  const { colorMode } = useColorMode();

  return (
    <>
      <NextSeo
        title='CronPay'
        description=''
        additionalLinkTags={[
          {
            rel: 'manifest',
            href: `/manifest-${colorMode}.json`
          }
        ]}
        additionalMetaTags={[
          {
            name: 'theme-color',
            content: themeColor
          },
          {
            name: 'msapplication-navbutton-color',
            content: themeColor
          }
        ]}
        {...seoOptions}
      />
      <Box maxW={{ base: '100%', lg: '8xl' }} px={{ base: 4, md: 10 }} pt={6} mx='auto' w='100%'>
        <Navbar />

        <Box mx='auto'>
          <Box align='left' textAlign='left' {...props} pb={12}>
            {children}
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default Page;
