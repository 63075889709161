import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useAuthState } from '../../../contexts/AuthContext';
import Loading from '../../Loading';
import { useOrgState } from '../../../contexts/OrgContext';

interface OrgSelectorProps {
  mobile?: boolean;
  onSelect?: () => void;
}

export const OrgSelector: FC<OrgSelectorProps> = ({ mobile, onSelect }) => {
  const router = useRouter();
  const { currentOrganisation, organisations, isLoading } = useOrgState();
  const { isAuthenticated } = useAuthState();
  const menuItemColor = useColorModeValue('brand.200', 'brand.500');
  const { isOpen } = useDisclosure();

  const rebuildUrl = (newOrg: string): string => {
    // Expecting /dashboard/orgID/...
    const url = router.asPath.split('/');
    // Replace current organisation ID with new organisation ID
    if (url.includes('dashboard')) {
      url[2] = newOrg;
      return url.join('/');
    }
    // Fallback to dashboard
    return `/dashboard/${newOrg}/`;
  };

  const handleClick = (organisationId) => {
    // setOrganisationById(organisationId);

    if (onSelect) {
      onSelect();
    }

    // router.push(rebuildUrl(organisationId));
    router.push(rebuildUrl(organisationId), null, { shallow: true });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (mobile) {
    return (
      <Accordion allowMultiple as='ul' listStyleType='none' px={4} borderColor='subtle-border'>
        <AccordionItem as='li'>
          <AccordionButton py='3' px='0' _hover={{ bg: 'inherit' }}>
            <Box flex='1' textAlign='start' fontSize='lg' fontWeight='semibold'>
              {currentOrganisation?.name}
            </Box>
            <AccordionIcon fontSize='3xl' />
          </AccordionButton>
          <AccordionPanel pb='3' px='0' pt='0'>
            {/* eslint-disable-next-line consistent-return,array-callback-return */}
            {organisations.map((org) => {
              if (org?.id !== currentOrganisation.id) {
                return (
                  <Link
                    href={org?.id}
                    /* @ts-ignore */
                    _hover={{ textDecoration: 'none' }}
                    key={org?.id}
                    onClick={() => handleClick(org.id)}
                  >
                    <HStack py='3' spacing='3'>
                      <Text fontWeight='medium' color='accent' ml={4}>
                        {org.name}
                      </Text>
                    </HStack>
                  </Link>
                );
              }
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <Box
      // align='center'
      p='4'
      mx='4'
      borderRadius='lg'
      role='group'
      cursor='pointer'
    >
      {!isLoading &&
        isAuthenticated &&
        // !isSettingCurrentOrganisation &&
        !currentOrganisation?.name &&
        router.asPath !== `/org-create/` && (
          <Center as='button' mt='6'>
            <Button>
              <Link href='/org-create/'>
                <AddIcon mr='4' />
                Add organisation
              </Link>
            </Button>
          </Center>
        )}
      {isAuthenticated && (
        <Menu>
          <MenuButton>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            {currentOrganisation?.name} {isOpen ? <ChevronUpIcon /> : currentOrganisation?.name && <ChevronDownIcon />}
          </MenuButton>
          <MenuList zIndex={99}>
            <MenuGroup title='Organisations'>
              {organisations.map((org) => {
                // menu item will not act as a link if menu item is selected
                if (currentOrganisation?.id === org?.id) {
                  return (
                    <MenuItem
                      id={org?.id}
                      key={org?.id}
                      isDisabled
                      onClick={() => handleClick(org.id)}
                      bgColor={menuItemColor}
                      _hover={{ bgColor: menuItemColor }}
                      _focus={{ bgColor: menuItemColor }}
                    >
                      {org.name}
                    </MenuItem>
                  );
                }
                return (
                  <Box key={org?.id} onClick={() => handleClick(org.id)}>
                    <MenuItem id={org.id} key={org.id}>
                      {org.name}
                    </MenuItem>
                  </Box>
                );
              })}
            </MenuGroup>
            <MenuDivider />

            {/* TODO: add scope to access button */}
            {/* <FeatureGuard orgScopes={[Scopes.ORG_CREATE]}> */}
            <Link href='/org-create'>
              <MenuItem icon={<AddIcon />}>Add new organisation</MenuItem>
            </Link>
            {/* </FeatureGuard> */}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};
