import React, { FC } from 'react';
import { Text, Flex, Spinner, FlexProps } from '@chakra-ui/react';

interface LoadingProps extends FlexProps {
  text?: string;
}

const Loading: FC<LoadingProps> = ({ text, ...props }) => (
  <Flex justifyContent='center' alignItems='center' h='100vh' {...props}>
    <Flex alignItems='center'>
      <Text mr='3'>{text}</Text>
      <Spinner />
    </Flex>
  </Flex>
);

export default Loading;
