import React from 'react';
import { Flex, FlexProps, Link, useColorModeValue } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useOrgState } from '../../contexts/OrgContext';

interface NavItemProps extends FlexProps {
  children: any;
  linkAddress: string;
}

export const NavItem = ({ linkAddress, children, ...rest }: NavItemProps) => {
  const { currentOrganisation } = useOrgState();
  const menuItemColor = useColorModeValue('brand.500', 'brand.400');
  if (currentOrganisation?.id) {
    return (
      <Link
        as={NextLink}
        href={`/dashboard/${currentOrganisation.id}/${linkAddress}`}
        style={{ textDecoration: 'none' }}
        _hover={{ textDecoration: 'none' }}
        color='body'
      >
        <Flex
          align='center'
          p='4'
          mx='4'
          borderRadius='lg'
          role='group'
          cursor='pointer'
          _hover={{
            bg: { menuItemColor },
            color: menuItemColor
          }}
          {...rest}
        >
          {children}
        </Flex>
      </Link>
    );
  }
  return null;
};
