import { Heading, HStack, Image } from '@chakra-ui/react';

export const Logo = () => {
  return (
    <HStack spacing={4}>
      <Image src='/images/logo.webp' w='71px' h='63px' alt='CronPay logo' />
      <Heading color='emphasized' as='h2' size={{ base: 'sm', md: 'md' }} fontWeight='extrabold'>
        CronPay
      </Heading>
    </HStack>
  );
};
