import * as React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import Link from 'next/link';

import { NavContent } from './navBar/NavContent';
import { Logo } from '../Logo';

interface NavbarProps {
  darkBackground?: boolean;
}

export const Navbar: FC<NavbarProps> = (props) => {
  return (
    <Box height='16' position='relative' zIndex='sticky'>
      <Box height='100%' mx='auto'>
        <Flex aria-label='CronPay navigation' align='center' justify='space-between' height='100%'>
          <Link href='/' passHref>
            <Logo />
          </Link>
          <NavContent.DesktopXlarge {...props} display={{ base: 'none', xl: 'flex' }} />
          <NavContent.DesktopLarge
            {...props}
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none' }}
          />
          <NavContent.Mobile {...props} display={{ md: 'flex', lg: 'none' }} />
        </Flex>
      </Box>
    </Box>
  );
};
